<template>
    <default-layout :request-in-progress="requestInProgress">
        <section class="p-3 dashed-bottom">
            <unit-header :unit="unit" :contract="activeContract" />
        </section>
        <section class="p-3 dashed-bottom">
            <div class="row">
                <div class="col-6 d-flex flex-column text-center align-items-center">
                    <i class="mb-2 mt-1 unit-status-icon rounded-circle" :class="unitStatus" />
                    <p class="mb-0 text-primary">{{ unitStatusHumanReadable }}</p>
                    <template v-if="isBlocked">
                        <div><small>by <b>{{ unit.blockade.created_by }}</b></small></div>
                        <div><small>until <b>{{ formatDate(unit.blockade.until) }}</b></small></div>
                        <div><small><i class="fa fa-ban" /> <i>{{ unit.blockade.description }}</i></small></div>
                    </template>
                    <template v-else-if="unitStatusTime">
                        <div>
                            <small>
                                <span v-if="isTime(unitStatusTime)">at </span>
                                <b>{{ unitStatusTime }}</b>
                            </small>
                        </div>
                    </template>
                    <template v-else-if="lastOccupationDate">
                        <div><small>since <b>{{ formatDate(lastOccupationDate) }}</b></small></div>
                    </template>
                </div>
                <div class="col-6 d-flex flex-column text-center align-items-center">
                    <template v-if="hasBeenInspected">
                        <i class="mb-2 fa fa-eye unit-inspection-icon" />
                        <p class="mb-0 text-primary">INSPECTED</p>
                        <p class="mb-0"><small>by  <b>{{ unit.last_unit_inspection_by }}</b></small></p>
                        <p class="mb-0"><small>on <b>{{ formatDate(unit.last_unit_inspection_date) }}</b></small></p>
                    </template>
                    <template v-else>
                        <i class="mb-2 fa fa-eye-slash unit-inspection-icon" />
                        <p class="mb-0 text-primary">NOT INSPECTED YET</p>
                    </template>
                </div>
            </div>
            <div class="row" v-if="unit.has_inactive_device">
                <div class="d-flex flex-column text-center align-items-center" style="margin-top: 16px">
                    <div style="display: inline-block; position: relative; margin-right: 5px;">
                        <i class="fa fa-rss"></i>
                        <i class="fa fa-exclamation-triangle fa-xs" style="position: absolute;  top: -20%; right: -50%;"></i>
                    </div>
                    <p class="mb-0 text-primary">DEVICE UNREACHABLE</p>
                </div>
            </div>
        </section>
        <section class="p-3 dashed-bottom">
            <h3 class="text-center mb-2">Todos</h3>
            <div class="row justify-content-center">
                <div class="col-4 d-flex" v-for="unitCheck in unitChecks" :key="unitCheck.id">
                    <div class="mb-3 d-flex flex-column align-items-center">
                        <i class="unit-check-fab mb-2"
                           :class=" [unitCheckIconsByType[unitCheck.unit_check_type_id],
                                     {'done': !!unitCheck.checked_at}]"
                           @click="goToUnitCheck(unitCheck.id)"/>
                        <div class="unit-check-description text-center">
                            {{ unitCheck.unit_check_type_description }}
                        </div>
                        <div class="unit-check-description text-center" v-if="unitCheck.contract_id">
                            <span class="text-info fw-bold font-small-1">{{ unitCheck.contract_id }}</span>
                        </div>
                    </div>
                </div>
                <template v-for="(meters, contractId) in metersByContractIdWhereNotOk" :key="contractId">
                    <div class="col-4 d-flex justify-content-center">
                        <div class="d-flex flex-column align-items-center">
                            <i class="unit-check-fab bg-primary mb-2 fa fa-tachometer-alt"
                               @click="goToMeter(contractId)"/>
                            <div class="unit-check-description text-center">
                                {{ meters.length > 1 ? "METERS" : "METER" }}
                            </div>
                            <div class="unit-check-description text-center">
                                <span class="text-info fw-bold font-small-1">{{ contractId }}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="col-4 d-flex" v-if="!hasActiveInspection">
                    <div class="d-flex flex-column align-items-center">
                        <i class="unit-check-fab bg-secondary mb-2"
                           :class="unitCheckIconsByType['check_thoroughly']"
                           @click="onStartThoroughInspection()"/>
                        <div class="unit-check-description text-center">
                            THOROUGH INSPECTION
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="mt-3">
            <h3 class="text-center mb-2">Jobs</h3>
            <div class="my-3 d-flex justify-content-center">
                <el-switch
                    class="mb-2"
                    style="display: block"
                    v-model="showScheduledJobsOnly"
                    active-color="#2196f3"
                    inactive-color="#2196f3"
                    active-text="Scheduled only"
                    inactive-text="All jobs"
                />
            </div>
            <job-accordion :jobs="jobs" v-if="jobs.length > 0" />
            <p class="text-center" v-else>There are no jobs for this unit.</p>
            <div class="my-4 d-flex justify-content-center">
                <button class="btn btn-secondary rounded-circle new-job-btn" @click="onNewJob()">
                    <i class="fa fa-plus" />
                </button>
            </div>
        </section>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import {buildingContext, contractContext, jobContext, meterContext, unitCheckContext, unitContext} from "@/store";
import ContractData from "@/http/data/contract-data";
import UnitData from "@/http/data/unit-data";
import UnitCheckData from "@/http/data/unit-check-data";
import JobData from "@/http/data/job-data";
import JobButton from "@/components/controls/JobButton.vue";
import JobGetters from "@/store/modules/job/job-getters";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckActions from "@/store/modules/unit-check/unit-check-actions";
import BuildingData from "@/http/data/building-data";
import JobActions from "@/store/modules/job/job-actions";
import JobAccordion from "@/components/controls/JobAccordion.vue";
import ContractMeterData from "@/http/data/contract-meter-data";
import UnitHeader from "@/components/ui/UnitHeader.vue";

import _ from "lodash";
import dayjs from "dayjs";

@Options({
    name: "Unit",
    components: {
        UnitHeader,
        JobAccordion,
        JobButton,
        DefaultLayout
    },
})
export default class Unit extends Vue {
    clearSelectedNewJobContract!: JobActions["clearSelectedNewJobContract"];
    clearSelectedNewJobUnitCheckTodoResult!: JobActions["clearSelectedNewJobUnitCheckTodoResult"];
    selectUnitCheck!: UnitCheckActions["selectUnitCheck"];
    startThoroughInspection!: UnitCheckActions["startThoroughInspection"];

    requestInProgress = false;

    beforeMount(): void {
        this.clearSelectedNewJobContract = jobContext.actions.clearSelectedNewJobContract;
        this.clearSelectedNewJobUnitCheckTodoResult = jobContext.actions.clearSelectedNewJobUnitCheckTodoResult;
        this.selectUnitCheck = unitCheckContext.actions.selectUnitCheck;
        this.startThoroughInspection = unitCheckContext.actions.startThoroughInspection;

        if (jobContext.state.selectedNewJobContractId) {
            this.clearSelectedNewJobContract();
        }

        if (jobContext.state.selectedNewJobUnitCheckTodoResultId) {
            this.clearSelectedNewJobUnitCheckTodoResult();
        }
    }

    get activeContract(): ContractData|null {
        return unitContext.getters.selectedUnitActiveContract;
    }

    get activeContractStatus(): string|null {
        if (!this.activeContract) return null;

        return contractContext.getters.getContractStatus(this.activeContract);
    }

    get building(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get contracts(): ContractData[] {
        return unitContext.getters.selectedUnitContracts;
    }

    get hasActiveInspection(): boolean {
        return !!this.unitChecks.find(uc => uc.unit_check_type_id === "check_thoroughly");
    }

    get hasBeenInspected(): boolean {
        return this.unit && !!this.unit.last_unit_inspection_date;
    }
    
    get isArriving(): boolean {
        return this.unitStatus === "status-arriving";
    }

    get isBlocked(): boolean {
        return this.unitStatus === "status-blocked";
    }

    get isDeparting(): boolean {
        return this.unitStatus === "status-departing";
    }

    get isOccupied(): boolean {
        return this.unitStatus === "status-occupied";
    }

    get isVacant(): boolean {
        return this.unitStatus === "status-vacant";
    }

    get jobs(): JobData[] {
        const jobs = this.unit.id ? this.jobsByUnitId[this.unit.id] : [];

        let jobsChain = _.chain(jobs).filter((job: JobData) => job.shouldIndex());

        if (this.showScheduledJobsOnly) {
            jobsChain = jobsChain.filter(job => dayjs().isSameOrAfter(job.perform_date));
        }
        
        return jobsChain
            .orderBy([(job: JobData) => job.perform_date], ["desc"])
            .value();
    }

    get jobsByUnitId(): JobGetters["jobsByUnitId"] {
        return jobContext.getters.jobsByUnitId;
    }

    get lastOccupationDate(): string|null {
        if (this.isOccupied) return this.activeContract?.start_date ?? null;
        else return this.unit.last_occupation_date ?? null;
    }

    get metersByContractIdWhereNotOk(): Record<string, ContractMeterData[]> {
        return _.chain(meterContext.state.contractMeters)
            .filter(meter => meter.unit_id === this.unit.id && !!meter.getNextRequiredDate())
            .groupBy(meter => meter.contract_id)
            .value();
    }

    get showScheduledJobsOnly(): boolean {
        return jobContext.state.showScheduledJobsOnly;
    }

    set showScheduledJobsOnly(value: boolean) {
        jobContext.mutations.setShowScheduledJobsOnly(value);
    }

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    get unitCheckIconsByType(): UnitCheckState["unitCheckIconsByType"] {
        return unitCheckContext.state.unitCheckIconsByType;
    }

    get unitChecks(): UnitCheckData[] {
        if (!this.unit.id) return [];

        return this.unitChecksByUnitId[this.unit.id] ?? [];
    }

    get unitChecksByUnitId(): UnitCheckGetters["unitChecksByUnitId"] {
        return unitCheckContext.getters.unitChecksByUnitId;
    }

    get unitStatus(): string {
        return unitContext.getters.selectedUnitStatus ?? "";
    }

    get unitStatusTime(): string|null {
        const unitStatusTime =  unitContext.getters.statusTime(this.activeContract, this.unitStatus, this.building);

        return unitStatusTime !== "-" ? unitStatusTime : null;
    }

    get unitStatusHumanReadable(): string {
        return this.unitStatus.substring(7).toUpperCase();
    }

    isTime(value: string): boolean {
        return /\d{2}:\d{2}/.test(value);
    }

    async goToMeter(contractId: string): Promise<void> {
        await meterContext.actions.selectMeterContractId(contractId);

        await this.$router.push({name: "Meters"});
    }

    async goToUnitCheck(unitCheckId: number): Promise<void> {
        const unitCheck = this.unitChecks.find(uc => uc.id === unitCheckId) as UnitCheckData;

        if (unitCheck.checked_at) return;

        await this.selectUnitCheck(unitCheckId);

        await this.$router.push({name: "UnitCheck"});
    }

    async onStartThoroughInspection(): Promise<void> {
        if (!this.unit.id) return;

        this.requestInProgress = true;

        await this.startThoroughInspection(this.unit.id);

        this.requestInProgress = false;

        await this.$router.push({name: "UnitCheck"});
    }

    async onNewJob(): Promise<void> {
        await this.$router.push({name: "JobNew"});
    }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.dashed-bottom {
    &:not(:last-child) {
        border-bottom: 2px $secondary dashed;
    }
}

.new-job-btn {
    width: 50px;
    height: 50px;
}

.unit-check-description {
    font-size: .8em;
}

.unit-check-fab {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light;
    background-color: $primary;
    font-size: 2em;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    &.done {
        background-color: $success;
    }

    &:hover:not(.done) {
        cursor: pointer;
        filter: brightness(.8);
    }
}

.unit-inspection-icon {
    font-size: 2em;
}

.unit-status-icon {
    width: 2em;
    height: 2em;
}

h1 {
    font-size: 2.5em;
    line-height: .75em;
}

h2 {
    line-height: .75em;
}

h3 {
    font-size: 2.5em;
}
</style>