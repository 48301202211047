
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import {buildingContext, contractContext, jobContext, meterContext, unitCheckContext, unitContext} from "@/store";
import ContractData from "@/http/data/contract-data";
import UnitData from "@/http/data/unit-data";
import UnitCheckData from "@/http/data/unit-check-data";
import JobData from "@/http/data/job-data";
import JobButton from "@/components/controls/JobButton.vue";
import JobGetters from "@/store/modules/job/job-getters";
import UnitCheckGetters from "@/store/modules/unit-check/unit-check-getters";
import UnitCheckState from "@/store/modules/unit-check/unit-check-state";
import UnitCheckActions from "@/store/modules/unit-check/unit-check-actions";
import BuildingData from "@/http/data/building-data";
import JobActions from "@/store/modules/job/job-actions";
import JobAccordion from "@/components/controls/JobAccordion.vue";
import ContractMeterData from "@/http/data/contract-meter-data";
import UnitHeader from "@/components/ui/UnitHeader.vue";

import _ from "lodash";
import dayjs from "dayjs";

@Options({
    name: "Unit",
    components: {
        UnitHeader,
        JobAccordion,
        JobButton,
        DefaultLayout
    },
})
export default class Unit extends Vue {
    clearSelectedNewJobContract!: JobActions["clearSelectedNewJobContract"];
    clearSelectedNewJobUnitCheckTodoResult!: JobActions["clearSelectedNewJobUnitCheckTodoResult"];
    selectUnitCheck!: UnitCheckActions["selectUnitCheck"];
    startThoroughInspection!: UnitCheckActions["startThoroughInspection"];

    requestInProgress = false;

    beforeMount(): void {
        this.clearSelectedNewJobContract = jobContext.actions.clearSelectedNewJobContract;
        this.clearSelectedNewJobUnitCheckTodoResult = jobContext.actions.clearSelectedNewJobUnitCheckTodoResult;
        this.selectUnitCheck = unitCheckContext.actions.selectUnitCheck;
        this.startThoroughInspection = unitCheckContext.actions.startThoroughInspection;

        if (jobContext.state.selectedNewJobContractId) {
            this.clearSelectedNewJobContract();
        }

        if (jobContext.state.selectedNewJobUnitCheckTodoResultId) {
            this.clearSelectedNewJobUnitCheckTodoResult();
        }
    }

    get activeContract(): ContractData|null {
        return unitContext.getters.selectedUnitActiveContract;
    }

    get activeContractStatus(): string|null {
        if (!this.activeContract) return null;

        return contractContext.getters.getContractStatus(this.activeContract);
    }

    get building(): BuildingData {
        return buildingContext.getters.selectedBuilding as BuildingData;
    }

    get contracts(): ContractData[] {
        return unitContext.getters.selectedUnitContracts;
    }

    get hasActiveInspection(): boolean {
        return !!this.unitChecks.find(uc => uc.unit_check_type_id === "check_thoroughly");
    }

    get hasBeenInspected(): boolean {
        return this.unit && !!this.unit.last_unit_inspection_date;
    }
    
    get isArriving(): boolean {
        return this.unitStatus === "status-arriving";
    }

    get isBlocked(): boolean {
        return this.unitStatus === "status-blocked";
    }

    get isDeparting(): boolean {
        return this.unitStatus === "status-departing";
    }

    get isOccupied(): boolean {
        return this.unitStatus === "status-occupied";
    }

    get isVacant(): boolean {
        return this.unitStatus === "status-vacant";
    }

    get jobs(): JobData[] {
        const jobs = this.unit.id ? this.jobsByUnitId[this.unit.id] : [];

        let jobsChain = _.chain(jobs).filter((job: JobData) => job.shouldIndex());

        if (this.showScheduledJobsOnly) {
            jobsChain = jobsChain.filter(job => dayjs().isSameOrAfter(job.perform_date));
        }
        
        return jobsChain
            .orderBy([(job: JobData) => job.perform_date], ["desc"])
            .value();
    }

    get jobsByUnitId(): JobGetters["jobsByUnitId"] {
        return jobContext.getters.jobsByUnitId;
    }

    get lastOccupationDate(): string|null {
        if (this.isOccupied) return this.activeContract?.start_date ?? null;
        else return this.unit.last_occupation_date ?? null;
    }

    get metersByContractIdWhereNotOk(): Record<string, ContractMeterData[]> {
        return _.chain(meterContext.state.contractMeters)
            .filter(meter => meter.unit_id === this.unit.id && !!meter.getNextRequiredDate())
            .groupBy(meter => meter.contract_id)
            .value();
    }

    get showScheduledJobsOnly(): boolean {
        return jobContext.state.showScheduledJobsOnly;
    }

    set showScheduledJobsOnly(value: boolean) {
        jobContext.mutations.setShowScheduledJobsOnly(value);
    }

    get unit(): UnitData {
        return unitContext.getters.selectedUnit as UnitData;
    }

    get unitCheckIconsByType(): UnitCheckState["unitCheckIconsByType"] {
        return unitCheckContext.state.unitCheckIconsByType;
    }

    get unitChecks(): UnitCheckData[] {
        if (!this.unit.id) return [];

        return this.unitChecksByUnitId[this.unit.id] ?? [];
    }

    get unitChecksByUnitId(): UnitCheckGetters["unitChecksByUnitId"] {
        return unitCheckContext.getters.unitChecksByUnitId;
    }

    get unitStatus(): string {
        return unitContext.getters.selectedUnitStatus ?? "";
    }

    get unitStatusTime(): string|null {
        const unitStatusTime =  unitContext.getters.statusTime(this.activeContract, this.unitStatus, this.building);

        return unitStatusTime !== "-" ? unitStatusTime : null;
    }

    get unitStatusHumanReadable(): string {
        return this.unitStatus.substring(7).toUpperCase();
    }

    isTime(value: string): boolean {
        return /\d{2}:\d{2}/.test(value);
    }

    async goToMeter(contractId: string): Promise<void> {
        await meterContext.actions.selectMeterContractId(contractId);

        await this.$router.push({name: "Meters"});
    }

    async goToUnitCheck(unitCheckId: number): Promise<void> {
        const unitCheck = this.unitChecks.find(uc => uc.id === unitCheckId) as UnitCheckData;

        if (unitCheck.checked_at) return;

        await this.selectUnitCheck(unitCheckId);

        await this.$router.push({name: "UnitCheck"});
    }

    async onStartThoroughInspection(): Promise<void> {
        if (!this.unit.id) return;

        this.requestInProgress = true;

        await this.startThoroughInspection(this.unit.id);

        this.requestInProgress = false;

        await this.$router.push({name: "UnitCheck"});
    }

    async onNewJob(): Promise<void> {
        await this.$router.push({name: "JobNew"});
    }
}
